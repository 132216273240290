<template>
  <div id="app">
    <Generator
      :daten="daten"
      :open="open"
      @close-generator="closeGenerator" />
  </div>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
      open: false,
      daten: {
        auswahl: {
          bereich: null,
          indikator: null,
          auspraegung: null,
          bezug: null,
          raumbezug: null,
          zeitbezug: null,
          darstellung: null
        },
        optionen: {
          showKlasseneinteilung: true,
          farben: 0,
          sortierung: null
        },
        mainData: null,
        glossar: {
          loading: false,
          obj: {}
        },
        sys: {
          publicPath: window.location.href === 'http://127.0.0.1:8080/' ? '/' : '/diagramm-generator-fe/'
        }
      }
    }
  },
  mounted () {
  },
  methods: {
    openGenerator () {
      this.open = true
      return true
    },
    closeGenerator () {
      this.open = false
      return true
    },
    toggleGenerator () {
      return this.open ? this.closeGenerator() : this.openGenerator()
    }
  },
  computed: {
  },
  watch: {
  },
  components: {
    Generator: () => import(/* webpackChunkName: "Generator" */ './components/Generator.vue')
  }
}
</script>

<style>
#app {
  position: fixed;
  right: 0;
  top: 0;
  width: 0;
  height: 100vh;
  z-index: 100000;
}
.icon-btn {
  border: none;
  background: #fff;
  padding: 0.2rem;
  width: 3.5rem;
  cursor: pointer;
  outline: none!important;
}
.cur-point {
  cursor: pointer;
}
.icon-btn > img {
  display: block;
  width: 100%;
  height: auto;
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
}
.icon-btn:hover > img, .icon-btn:focus > img {
  opacity: 1;
}
.mb-1, .my-1, .m-1 {
  margin-bottom: 1rem;
}
.mb-2, .my-2, .m-2 {
  margin-bottom: 2rem;
}
.mb-3, .my-3, .m-3 {
  margin-bottom: 3rem;
}
.mb-4, .my-4, .m-4 {
  margin-bottom: 4rem;
}
.mt-1, .my-1, .m-1 {
  margin-top: 1rem;
}
.mt-2, .my-2, .m-2 {
  margin-top: 2rem;
}
.mt-3, .my-3, .m-3 {
  margin-top: 3rem;
}
.mt-4, .my-4, .m-4 {
  margin-top: 4rem;
}
.ml-1, .mx-1, .m-1 {
  margin-left: 1rem;
}
.ml-2, .mx-2, .m-2 {
  margin-left: 2rem;
}
.ml-3, .mx-3, .m-3 {
  margin-left: 3rem;
}
.ml-4, .mx-4, .m-4 {
  margin-left: 4rem;
}
.mr-1, .mx-1, .m-1 {
  margin-right: 1rem;
}
.mr-2, .mx-2, .m-2 {
  margin-right: 2rem;
}
.mr-3, .mx-3, .m-3 {
  margin-right: 3rem;
}
.mr-4, .mx-4, .m-4 {
  margin-right: 4rem;
}
.dg-hint-text {
  color: #333;
  text-align: center;
  font-size: 1.5rem;
}
</style>
